import { FC } from 'react';

import { IoIosArrowRoundUp } from '@react-icons/all-files/io/IoIosArrowRoundUp';
import { BackTop as AntBackTop } from 'antd';

import { StyledOrangeColor } from '../SharedStyles';

const BackTop: FC = () => {
  return (
    <AntBackTop aria-label="Back to top">
      <StyledOrangeColor style={{ fontSize: '50px' }}>
        <IoIosArrowRoundUp />
      </StyledOrangeColor>
    </AntBackTop>
  );
};

export default BackTop;
