import styled from '@emotion/styled';
import { Radio } from 'antd';

import { colors } from '../../theme';

export const StyledRatioContainer = styled.div`
  display: flex;
`;

export const StyledRadio = styled(Radio)`
  &&& {
    .ant-radio {
      &::hover {
        border-color: ${colors.primary.orange};
      }
    }

    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
      border-color: ${colors.primary.orange};
    }

    .ant-radio-checked .ant-radio-inner {
      &::after {
        background-color: ${colors.primary.orange};
      }
      border-color: transparent;
    }
  }
`;
