import { css } from '@emotion/react';
import { colors } from '@sotware-inc/react-components';
import emotionReset from 'emotion-reset';

import { antDesignGlobals, primaryFont, vertigoGlobals } from './theme';

export const globalStyles = css`
  ${emotionReset};
  ${antDesignGlobals}
  ${vertigoGlobals}
  
  input[type='text']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type='text']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
  input[type='button'] {
    appearance: none;
  }

  html,
  body {
    position: relative;
    height: 100%;
    width: 100%;

    background: ${colors.primary.black};
    color: ${colors.primary.white};
  }

  body {
    ${primaryFont}
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    z-index: 0;
  }

  body {
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  body.change-dialog-open {
    overflow-y: hidden;
  }

  * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
  }
`;

export const sectionStyle = css`
  height: 100%;
  width: 100%;
  user-select: none;
`;
