import { FC } from 'react';

import { IconType } from '@react-icons/all-files/lib/esm';
import { Typography } from 'antd';

import {
  buildBodyTextStyles,
  buildIconContainer,
  StyledIconContainerProps,
  StyledTextContainer
} from './BodyText.styles';

interface BodyTextProps {
  Icon?: IconType;
  iconProps?: StyledIconContainerProps;
  linked?: boolean;
  href?: string;
}

const BodyText: FC<BodyTextProps> = ({ children, Icon, iconProps, linked, href }) => {
  const renderBodyText: FC = ({ children: bodyTextChildren }) => {
    const bodyText = <Typography.Text css={buildBodyTextStyles({ linked })}>{bodyTextChildren}</Typography.Text>;

    if (Icon !== undefined) {
      return (
        <StyledTextContainer>
          <span css={buildIconContainer(iconProps)}>
            <Icon />
          </span>
          {bodyText}
        </StyledTextContainer>
      );
    }

    return bodyText;
  };

  if (linked) {
    return (
      <a target="_blank" rel="noopener noreferrer" href={href}>
        {renderBodyText({ children })}
      </a>
    );
  }

  return renderBodyText({ children });
};

export default BodyText;
