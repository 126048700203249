import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Input } from 'antd';

import { colors } from '../../theme';

const inputStyles = css`
  background-color: ${colors.primary.meniumGrey};
  border: none;
  padding: 15px 0 15px 50px;
  color: ${colors.primary.white};
  border: 1px solid transparent;
  letter-spacing: 1px;

  &:focus {
    outline: none !important;
    border: 1px solid ${colors.primary.orange};
    box-shadow: 0 0 10px ${colors.primary.orange};
  }
`;

export const StyledTextField = styled(Input)`
  &&& {
    ${inputStyles}
  }
`;

export const StyledTextArea = styled(Input.TextArea)`
  &&& {
    ${inputStyles}
  }
`;
