import { FC } from 'react';

import { Col, Layout, Row } from 'antd';
import { Link } from 'gatsby';

import { colors } from '../../theme';
import BodyText from '../BodyText/BodyText';
import Divider from '../Divider/Divider';
import PageContainer from '../PageContainer/PageContainer';
import { StyledCenterText, StyledMTopBottomMargin } from '../SharedStyles';
import { StyledCaption } from './Footer.styled';

const Footer: FC = () => {
  const footerText =
    'Vertigo Property Group sp. j., Cedet, ul. Krucza 50, 00-025 Warszawa, tel. +48 22 164 78 28, e-mail: kontakt@vertigoproperty.pl KRS: 0000441164, Sąd Rejonowy dla m. st. Warszawy w Warszawie XII Wydział Gospodarczy Krajowego Rejestru Sądowego NIP: 525-24-35-817, REGON: 146692967';

  return (
    <Layout.Content style={{ backgroundColor: colors.primary.grey }}>
      <PageContainer>
        <StyledCenterText>
          <StyledMTopBottomMargin>
            <Row style={{ width: '900px', margin: '0px auto', maxWidth: '100%' }}>
              <Divider variant="medium" />
              <Col span={24}>
                <StyledCaption type="secondary">{footerText}</StyledCaption>
              </Col>
              <Divider variant="small" />
              <Col span={24}>
                <Link to="/regulamin">
                  <BodyText>Regulamin</BodyText>
                </Link>
              </Col>
            </Row>
          </StyledMTopBottomMargin>
        </StyledCenterText>
      </PageContainer>
    </Layout.Content>
  );
};

export default Footer;
