import styled from '@emotion/styled';
import { Typography } from 'antd';

import { colors } from '../../theme';

export const StyledCaption = styled(Typography.Text)`
  &&& {
    color: ${colors.primary.white};
    font-size: 0.75rem;
  }
`;
