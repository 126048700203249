import * as Yup from 'yup';

export const useContactSchema = (): Yup.BaseSchema => {
  const requiredText = 'To pole jest wymagane';
  const notValidEmail = 'Email nie jest poprawny';
  const notValidPhoneNumber = 'Wpisz poprawny numer telefonu';
  const tooLongText = 'Za długi tekst';
  const phoneRegx = /^(\+48)+[0-9]{9}$|^[0-9]{9}$/;

  return Yup.object().shape({
    email: Yup.string().email(notValidEmail).required(requiredText),
    phone: Yup.string()
      .required(requiredText)
      .test('is phone number correct', notValidPhoneNumber, (phone) =>
        phoneRegx.test(phone?.trim().replace(/ +/g, '') ?? '')
      ),
    consens: Yup.boolean().test('true consens', requiredText, (consens) => consens === true),
    help: Yup.string().max(1000, tooLongText)
  });
};
