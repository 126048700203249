import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Input, Radio } from 'antd';

import { colors, mediaMaxWidth } from '../../theme';
import TitleWithLine from '../TitleWithLine/TitleWithLine';

export const StyledRatioContainer = styled.div`
  display: flex;
`;

const inputStyles = css`
  background-color: ${colors.primary.meniumGrey};
  border: none;
  padding: 15px 0 15px 50px;
  color: ${colors.primary.white};
  border: 1px solid transparent;

  &:focus {
    outline: none !important;
    border: 1px solid ${colors.primary.orange};
    box-shadow: 0 0 10px ${colors.primary.orange};
  }
`;

export const StyledInput = styled(Input)`
  &&& {
    ${inputStyles}
  }
`;

export const StyledTextArea = styled(Input.TextArea)`
  &&& {
    ${inputStyles}
  }
`;

export const StyledLink = styled(TitleWithLine)`
  &&& {
    font-size: 1.2rem;
    line-height: 3.5;

    ${mediaMaxWidth('md')} {
      font-size: 0.8rem;
    }
  }
`;

export const StyledRadio = styled(Radio)`
  &&& {
    .ant-radio {
      &::hover {
        border-color: ${colors.primary.orange};
      }
    }

    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
      border-color: ${colors.primary.orange};
    }

    .ant-radio-checked .ant-radio-inner {
      &::after {
        background-color: ${colors.primary.orange};
      }
      border-color: transparent;
    }
  }
`;
