import { FC, FocusEvent } from 'react';

import { FieldProps } from 'formik';
import { get } from 'lodash';

import TextField, { TextFieldProps } from '../TextField/TextField';

type KeysToRemove = 'name' | 'value' | 'error';

interface TextFieldWithFormikProps extends FieldProps, Omit<TextFieldProps, KeysToRemove> {}

export function fieldToTextField({
  disabled,
  field: { onBlur: fieldOnBlur, ...formikField },
  form: { isSubmitting, touched, errors },
  onBlur,
  helperText,
  ...props
}: TextFieldWithFormikProps): TextFieldProps {
  const fieldError = get(errors, formikField.name);
  const showError = get(touched, formikField.name) === true && fieldError !== undefined;

  return {
    error: showError,
    helperText,
    errorText: fieldError?.toString(),
    disabled: disabled ?? isSubmitting,
    onBlur:
      onBlur ??
      function onB(event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        fieldOnBlur(event);
      },
    ...formikField,
    ...props
  };
}

const TextFieldFormik: FC<TextFieldWithFormikProps> = (props) => {
  const { ...textFieldProps } = fieldToTextField(props);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <TextField {...textFieldProps} />;
};

export default TextFieldFormik;
