import React, { FC } from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Facebook from './Facebook';
import Twitter from './Twitter';

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        defaultTitle: title
        defaultDescription: description
        defaultBanner: banner
        defaultKeywords: keywords
        headline
        siteLanguage
        ogLanguage
        author
      }
    }
  }
`;

interface SEOProps {
  title?: string;
  desc?: string;
  banner?: string;
  pathname?: string;
  keywords?: string;
}

const SEO: FC<SEOProps> = ({ title, desc, banner, pathname, keywords }) => {
  const {
    site: {
      buildTime,
      siteMetadata: {
        siteUrl,
        defaultTitle,
        defaultDescription,
        defaultKeywords,
        defaultBanner,
        headline,
        siteLanguage,
        ogLanguage,
        author
      }
    }
  } = useStaticQuery(query);

  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    keywords: keywords || defaultKeywords,
    image: `${siteUrl}${banner || defaultBanner}`,
    url: `${siteUrl}${pathname || ''}`
  };

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: siteUrl,
    headline,
    inLanguage: siteLanguage,
    mainEntityOfPage: siteUrl,
    description: defaultDescription,
    name: defaultTitle,
    author: {
      '@type': 'Person',
      name: author
    },
    copyrightHolder: {
      '@type': 'Person',
      name: author
    },
    copyrightYear: '2021',
    creator: {
      '@type': 'Person',
      name: author
    },
    publisher: {
      '@type': 'Person',
      name: author
    },
    datePublished: '2019-01-18T10:30:00+01:00',
    dateModified: buildTime,
    image: {
      '@type': 'ImageObject',
      url: `${siteUrl}${defaultBanner}`
    }
  };

  // Initial breadcrumb list

  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': siteUrl,
        name: 'Homepage'
      },
      position: 1
    }
  ];

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement
  };

  return (
    <>
      <Helmet
        title={seo.title}
        htmlAttributes={{
          lang: siteLanguage
        }}
        meta={[
          {
            name: 'description',
            content: seo.description
          },
          {
            name: 'keywords',
            content: seo.keywords
          },
          {
            name: 'image',
            content: seo.image
          }
        ]}
      >
        <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type="website"
        url={seo.url}
        locale={ogLanguage}
        name={undefined}
      />
      <Twitter title={seo.title} image={seo.image} desc={seo.description} username={undefined} />
    </>
  );
};

export default SEO;
