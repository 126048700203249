import styled from '@emotion/styled';

import { mediaMaxWidth } from '../../theme';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.8;
  padding-left: 10%;
  padding-right: 10%;

  ${mediaMaxWidth('lg')} {
    padding-left: 7%;
    padding-right: 7%;
  }

  ${mediaMaxWidth('md')} {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;
