import { FC } from 'react';

import { Divider as AntDivider } from 'antd';

import { buildDividerStyles, DividerVariant } from './Divider.styled';

interface DividerProps {
  prefixCls?: string;
  type?: 'horizontal' | 'vertical';
  orientation?: 'left' | 'right' | 'center';
  className?: string;
  children?: React.ReactNode;
  dashed?: boolean;
  plain?: boolean;
  variant?: DividerVariant;
}

const Divider: FC<DividerProps> = ({ prefixCls, type, orientation, className, children, plain, variant }) => {
  const dividerStyles = buildDividerStyles(variant, type);

  return (
    <AntDivider
      css={dividerStyles}
      plain={plain}
      prefixCls={prefixCls}
      type={type}
      orientation={orientation}
      className={className}
    >
      {children}
    </AntDivider>
  );
};

export default Divider;
