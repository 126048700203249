import { css, SerializedStyles } from '@emotion/react';

export type DividerVariant = 'small' | 'medium' | 'large';

export const buildDividerStyles = (variant?: DividerVariant, type?: 'horizontal' | 'vertical'): SerializedStyles => {
  let dividerStyles: SerializedStyles | null = null;

  if (type === 'horizontal' || type === undefined) {
    if (variant === 'small') {
      dividerStyles = css`
        margin-top: 4px;
        margin-bottom: 4px;
      `;
    }

    if (variant === 'medium') {
      dividerStyles = css`
        margin-top: 10px;
        margin-bottom: 10px;
      `;
    }

    if (variant === 'large') {
      dividerStyles = css`
        margin-top: 15px;
        margin-bottom: 15px;
      `;
    }
  }

  if (type === 'vertical') {
    if (variant === 'small') {
      dividerStyles = css`
        margin-left: 4px;
        margin-right: 4px;
      `;
    }

    if (variant === 'medium') {
      dividerStyles = css`
        margin-left: 10px;
        margin-right: 10px;
      `;
    }

    if (variant === 'large') {
      dividerStyles = css`
        margin-left: 15px;
        margin-right: 15px;
      `;
    }
  }

  return css`
    &&& {
      border-top: ${type === 'horizontal' ? '1px solid transparent' : 0};
      border-left: ${type === 'vertical' ? '1px solid transparent' : 0};
      ${dividerStyles}
    }
  `;
};
