import styled from '@emotion/styled';

import { colors, margins, mediaMaxWidth } from '../theme';

export const StyledXxlTopMargin = styled.div`
  margin-top: ${margins.xxl};
  ${mediaMaxWidth('md')} {
    margin-top: 0;
  }
`;

export const StyledXlTopMargin = styled.div`
  margin-top: ${margins.xl};
`;

export const StyledXlTopBottomMargin = styled.div`
  margin-top: ${margins.xl};
  margin-bottom: ${margins.xl};
`;

export const StyledLTopBottomMargin = styled.div`
  margin-top: ${margins.l};
  margin-bottom: ${margins.l};
`;

export const StyledMTopBottomMargin = styled.div`
  margin-top: ${margins.m};
  margin-bottom: ${margins.m};
`;

export const StyledLTopMargin = styled.div`
  margin-top: ${margins.l};
`;

export const StyledLBottomMargin = styled.div`
  margin-bottom: ${margins.l};
`;

export const StyledOrangeColor = styled.span`
  color: ${colors.primary.orange};
`;

export const StyledCenterText = styled.span`
  text-align: center;
`;

export const StyledNotMobileLeftPadding = styled.div`
  padding-left: 30px;

  ${mediaMaxWidth('md')} {
    padding-left: 0;
  }
`;

export const StyledNotMobileRightPadding = styled.div`
  padding-right: 30px;

  ${mediaMaxWidth('md')} {
    padding-right: 0;
  }
`;
