import React, { FC, useState } from 'react';

import { AiOutlineLinkedin } from '@react-icons/all-files/ai/AiOutlineLinkedin';
import { BiMailSend } from '@react-icons/all-files/bi/BiMailSend';
import { MdLocalPhone } from '@react-icons/all-files/md/MdLocalPhone';
import { Layout, Row, Col, Space, message } from 'antd';
import { Field, Formik, Form, FormikHelpers } from 'formik';
import { graphql, useStaticQuery } from 'gatsby';
import { Fade } from 'react-awesome-reveal';

import { Section } from '../../models/Section';
import { useContactSchema } from '../../schemas/useContactSchema';
import { StyledTextContainer } from '../BodyText/BodyText.styles';
import Button from '../Button/Button';
import Divider from '../Divider/Divider';
import { FormikRadio } from '../FormikRadio/FormikRadio';
import TextFieldFormik from '../FormikTextField/FormikTextField';
import PageContainer from '../PageContainer/PageContainer';
import { StyledXlTopBottomMargin } from '../SharedStyles';
import TitleWithLine from '../TitleWithLine/TitleWithLine';
import { StyledLink } from './ContactMe.styles';

interface ContactData {
  email: string;
  phone: string;
  help: string;
  consens?: boolean;
}

const query = graphql`
  query ContactMeQuery {
    site {
      siteMetadata {
        phone
        linkedin
        email
      }
    }
  }
`;

const ContactMe: FC = () => {
  const {
    site: {
      siteMetadata: { phone, email, linkedin }
    }
  } = useStaticQuery(query);

  const [loading, setLoading] = useState<boolean>(false);
  const contactShema = useContactSchema();
  const contactMeTitle = 'Kontakt'.toUpperCase();
  const anchorPhoneNumber = `tel:${phone}`;
  const anchorEmail = `mailto:${email}`;
  const name = 'Link do mojego profilu'.toUpperCase();

  const formHelpText = 'W czym mogę pomóc?'.toUpperCase();
  const formEmailText = 'Adres email*'.toUpperCase();
  const formPhoneText = 'Telefon*'.toUpperCase();

  const buttonText = 'WYŚLIJ';

  const initialData: ContactData = {
    email: '',
    phone: '',
    help: '',
    consens: undefined
  };

  const onSubmit = async (contact: ContactData, { validateForm }: FormikHelpers<ContactData>): Promise<void> => {
    const formErrors = await validateForm(contact);
    setLoading(true);
    if (formErrors) {
      setTimeout(() => {
        message.success('Dziękuję za kontakt! \n Mail został wysłany. ');
        setLoading(false);
      }, 2000);
    }
  };

  return (
    <Layout.Content id={Section.CONTACT_ME}>
      <PageContainer>
        <StyledXlTopBottomMargin>
          <Row>
            <Col span={24}>
              <TitleWithLine>{contactMeTitle}</TitleWithLine>
            </Col>
          </Row>
          <Divider type="horizontal" />

          <Row justify="center">
            <Col xl={{ span: 12 }} lg={{ span: 12 }} xs={{ span: 24, offset: 0 }}>
              <Fade>
                <Space direction="vertical">
                  <StyledLink Icon={MdLocalPhone} rightLine={false}>
                    <a target="_blank" rel="noopener noreferrer" href={anchorPhoneNumber}>
                      {phone}
                    </a>
                  </StyledLink>
                  <StyledLink Icon={BiMailSend} rightLine={false}>
                    <a target="_blank" rel="noopener noreferrer" href={anchorEmail}>
                      {email.toUpperCase()}
                    </a>
                  </StyledLink>
                  <StyledLink Icon={AiOutlineLinkedin} rightLine={false}>
                    <a target="_blank" rel="noopener noreferrer" href={linkedin}>
                      {name}
                    </a>
                  </StyledLink>
                </Space>
              </Fade>
            </Col>

            <Col xl={{ span: 12 }} lg={{ span: 12 }} xs={{ span: 24, offset: 0 }}>
              <Fade>
                <Formik
                  initialValues={initialData}
                  validationSchema={contactShema}
                  onSubmit={onSubmit}
                  validateOnChange={false}
                  validateOnBlur={false}
                >
                  <Form>
                    <Field component={TextFieldFormik} name="email" label={formEmailText} placeholder={formEmailText} />
                    <Divider type="horizontal" />
                    <Field
                      component={TextFieldFormik}
                      name="phone"
                      type="text"
                      label={formPhoneText}
                      placeholder={formPhoneText}
                    />
                    <Divider type="horizontal" />
                    <Field
                      component={TextFieldFormik}
                      name="help"
                      label={formHelpText}
                      placeholder={formHelpText}
                      multiline
                    />
                    <Divider type="horizontal" />
                    <Field component={FormikRadio} aria-label="Zgoda" type="checkbox" name="consens" />
                    <Divider type="horizontal" />
                    <StyledTextContainer>
                      <Button aria-label={buttonText} key="submit" htmlType="submit" loading={loading} ghost>
                        {buttonText}
                      </Button>
                    </StyledTextContainer>
                  </Form>
                </Formik>
              </Fade>
            </Col>
          </Row>
        </StyledXlTopBottomMargin>
      </PageContainer>
    </Layout.Content>
  );
};

export default ContactMe;
