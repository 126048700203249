import React, { FC } from 'react';

import { Typography } from 'antd';
import { FieldProps } from 'formik';
import { Link } from 'gatsby';
import { get } from 'lodash';
import { AttentionSeeker } from 'react-awesome-reveal';

import BodyText from '../BodyText/BodyText';
import { StyledOrangeColor } from '../SharedStyles';
import { StyledRadio, StyledRatioContainer } from './FormikRadio.styles';

interface RadioProps {
  checked: boolean;
  onClick: () => void;
  disabled: boolean;
  error?: boolean;
  errorText?: string;
}

interface RadioWithFormikProps extends FieldProps, RadioProps {}

export function fieldToRadio({
  field: { onBlur: fieldOnBlur, ...formikField },
  form: { errors },
  ...props
}: RadioWithFormikProps): RadioProps {
  const fieldError = get(errors, formikField.name);
  const showError = get(errors, formikField.name) !== undefined && fieldError !== undefined;

  return {
    error: showError,
    errorText: fieldError?.toString(),
    ...formikField,
    ...props
  };
}

export const FormikRadio: FC<RadioWithFormikProps> = (props) => {
  const { errorText, error, ...radioProps } = fieldToRadio(props);

  return (
    <>
      <StyledRatioContainer>
        <StyledRadio
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...radioProps}
        />
        <BodyText>
          *Oświadczam, że zapoznałem/łam się z treścią klauzuli informacyjnej i wyrażam zgodę na przetwarzanie moich
          danych osobowych (RODO) przez spółkę Vertigo Property Group oraz otrzymywanie drogą elektroniczną informacji
          handlowej.{' '}
          <Link to="/regulamin">
            <BodyText>
              {' '}
              <StyledOrangeColor>Czytaj więcej...</StyledOrangeColor>
            </BodyText>
          </Link>
        </BodyText>
      </StyledRatioContainer>
      {error ? (
        <AttentionSeeker effect="shake" triggerOnce>
          <Typography.Text type="danger">{errorText}</Typography.Text>
        </AttentionSeeker>
      ) : null}
    </>
  );
};
