import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';

import { colors } from '../../theme';

export const StyledTextContainer = styled.div`
  position: relative;
`;

const sharedTextStyles = css`
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 100;
`;

interface StyledBodyTextProps {
  linked?: boolean;
}

export const buildBodyTextStyles = ({ linked }: StyledBodyTextProps): SerializedStyles => {
  if (linked) {
    return css`
      &&& {
        ${sharedTextStyles}
        color: ${colors.primary.white};
        text-decoration: underline;
      }
    `;
  }

  return css`
    &&& {
      ${sharedTextStyles}
      color: ${colors.primary.white};
    }
  `;
};

export interface StyledIconContainerProps {
  size?: number;
}

export const buildIconContainer = (iconProps?: StyledIconContainerProps): SerializedStyles => {
  return css`
    svg {
      position: absolute;
      left: -25px;
      top: 3px;
      font-size: ${iconProps?.size ?? '18'}px;
      color: ${colors.primary.orange};
    }
  `;
};
