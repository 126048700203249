import { css } from '@emotion/react';

export const colors = {
  primary: {
    orange: '#EF4923',
    black: '#1F1F1F',
    lightBlack: '#222222',
    grey: '#333333',
    meniumGrey: '#363636',
    lightGrey: '#4A4747',
    white: '#FFFFFF'
  }
};

export const antDesignGlobals = css`
  html {
    --antd-wave-shadow-color: ${colors.primary.orange};
  }
`;

export const vertigoGlobals = css`
  ::selection {
    background: ${colors.primary.orange};
  }
`;

export const primaryFont = css`
  font-family: 'Avenir', sans-serif;
`;

export const margins = {
  s: '10px',
  m: '20px',
  l: '50px',
  xl: '100px',
  xxl: '180px'
};

export interface BreakpointConfig {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
}

export interface VerticalBreakpointConfig {
  xs: number;
  sm: number;
  md: number;
}

export const breakpoints: BreakpointConfig = {
  xs: 360,
  sm: 600,
  md: 992,
  lg: 1200,
  xl: 1920
};

export const heightBreakPoints: VerticalBreakpointConfig = {
  xs: 360,
  sm: 720,
  md: 850
};

export const mediaMaxWidth = (size: keyof BreakpointConfig): string => {
  return `@media (max-width: ${breakpoints[size]}px)`;
};

export const mediaMaxHeight = (size: keyof VerticalBreakpointConfig): string => {
  return `@media (max-height: ${heightBreakPoints[size]}px)`;
};
