import React, { FC } from 'react';

import { Typography } from 'antd';
import { AttentionSeeker } from 'react-awesome-reveal';

import { StyledTextArea, StyledTextField } from './TextField.styles';

export interface TextFieldProps {
  label?: string;
  name: string;
  type?: string;
  value?: string;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  placeholder?: string;
  errorText?: string;
  multiline?: boolean;
  className?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const TextField: FC<TextFieldProps> = ({
  errorText,
  name,
  error,
  value,
  onBlur,
  onChange,
  disabled,
  placeholder,
  className,
  type,
  multiline,
  label
}) => {
  // const captionMessage = error === true ? (errorText !== undefined ? errorText : helperText) : helperText;

  if (multiline) {
    return (
      <>
        <StyledTextArea
          id={name}
          value={value}
          placeholder={placeholder}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          disabled={disabled}
          className={className}
          aria-label={label}
        />
        {error === true && errorText !== undefined ? (
          <AttentionSeeker effect="shake" triggerOnce>
            <Typography.Text type="danger">{errorText}</Typography.Text>
          </AttentionSeeker>
        ) : null}
      </>
    );
  }

  return (
    <>
      <StyledTextField
        id={name}
        value={value}
        placeholder={placeholder}
        name={name}
        type={type}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
        className={className}
        aria-label={label}
      />
      {error === true && errorText !== undefined ? (
        <AttentionSeeker effect="shake" triggerOnce>
          <Typography.Text type="danger">{errorText}</Typography.Text>
        </AttentionSeeker>
      ) : null}
    </>
  );
};

export default TextField;
