import { FC } from 'react';

import { IconType } from '@react-icons/all-files/lib/esm';
import { Typography } from 'antd';

import { StyledTitleProps, StyledTitleIconContainer, buildTitleStyles } from './TitleWithLine.styles';

interface TitleWithLineProps extends Omit<StyledTitleProps, 'textCount'> {
  children: any;
  className?: string;
  Icon?: IconType;
  level?: 1 | 2 | 3 | 4 | 5;
}

const TitleWithLine: FC<TitleWithLineProps> = ({ children, rightLine = true, Icon, className, level = 2 }) => {
  return (
    <Typography.Title
      className={className}
      css={buildTitleStyles({ rightLine, textCount: children.length, withIcon: Icon !== undefined })}
      level={level}
    >
      {Icon !== undefined ? (
        <StyledTitleIconContainer>
          <Icon />
        </StyledTitleIconContainer>
      ) : null}
      {children}
    </Typography.Title>
  );
};

export default TitleWithLine;
