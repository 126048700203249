import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from 'antd';

import { colors } from '../../theme';

const buildButtonStyles = (ghost?: boolean): SerializedStyles => {
  if (ghost) {
    return css`
      border-width: 5px;
      border-color: ${colors.primary.orange};

      &:before {
        background: none;
      }

      &:active {
        border-color: ${colors.primary.white};
      }
    `;
  }

  return css`
    background-color: ${colors.primary.orange};
    border: none;

    &::hover {
      background-color: ${colors.primary.black};
    }
  `;
};

export const StyledButton = styled(Button)`
  &&& {
    width: 240px;
    height: 53px;
    font-weight: bold;
    font-size: 20px;
    color: ${colors.primary.white};
    ${({ ghost }) => buildButtonStyles(ghost)}
  }
`;
