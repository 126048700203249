import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';

import { colors, mediaMaxWidth } from '../../theme';

export interface StyledTitleProps {
  textCount: number;
  rightLine?: boolean;
  withIcon?: boolean;
}

const sharedStyles = css`
  position: relative;
  color: ${colors.primary.white};
  font-size: 1.9rem;
`;

export const buildTitleStyles = ({ rightLine, textCount, withIcon }: StyledTitleProps): SerializedStyles => {
  let titleStyles: SerializedStyles | null = null;

  if (rightLine) {
    titleStyles = css`
      ${sharedStyles}
      &:before {
        content: '';
        position: absolute;
        left: ${textCount + 4}rem;
        top: 45%;
        width: 92px;
        height: 3px;
        background-color: ${colors.primary.orange};
      }
    `;
  }

  if (withIcon) {
    titleStyles = css`
      ${sharedStyles}
      display: flex;
      align-items: center;
    `;
  }

  return css`
    &&& {
      position: relative;
      color: ${colors.primary.white};
      font-size: 1.9rem;
      ${titleStyles}
      a {
        color: ${colors.primary.white};
      }

      ${mediaMaxWidth('md')} {
        font-size: 1.6rem;
      }
    }
  `;
};

export const StyledTitleIconContainer = styled.div`
  display: flex;
  margin-right: 20px;

  svg {
    font-size: 60px;
    color: ${colors.primary.orange};
  }
`;
