import { FC } from 'react';

import { ButtonProps as AntButtonProps } from 'antd/lib/button';

import { StyledButton } from './Button.styles';

interface ButtonProps extends AntButtonProps {
  children: string;
  onClick?: () => void;
}

const Button: FC<ButtonProps> = ({ children, onClick, ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledButton onClick={onClick} {...props}>
      {children}
    </StyledButton>
  );
};

export default Button;
