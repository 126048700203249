import React, { FC } from 'react';

import { Global } from '@emotion/react';

import { globalStyles } from '../../globalStyles';
import { StyledLayout } from './Layout.styles';

const Layout: FC = ({ children }) => {
  return (
    <>
      <Global styles={globalStyles} />
      <StyledLayout>{children}</StyledLayout>
    </>
  );
};

export default Layout;
